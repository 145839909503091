/*Æ=======================================================
 * ETEC | ETEC PORTAL
 * Copyright 2022 ETEC
 * Version 1.0
=======================================================Æ*/
@charset "UTF-8";

/* @font-face {
  font-family: 'DINNext';
  src: url('../fonts/DINNext/DINNextLTW23-UltraLight.woff') format('woff'),
    url('../fonts/DINNext/DINNextLTW23-UltraLight.ttf') format('truetype'),
    url('../fonts/DINNext/DINNextLTW23-UltraLight.svg#DINNextLTW23-UltraLight') format('svg');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DINNext';
  src: url('../fonts/DINNext/DINNextLTW23-Regular.woff') format('woff'),
    url('../fonts/DINNext/DINNextLTW23-Regular.ttf') format('truetype'),
    url('../fonts/DINNext/DINNextLTW23-Regular.svg#DINNextLTW23-Regular') format('svg');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DINNext';
  src: url('../fonts/DINNext/DINNextLTW23-Medium.woff') format('woff'),
    url('../fonts/DINNext/DINNextLTW23-Medium.ttf') format('truetype'),
    url('../fonts/DINNext/DINNextLTW23-Medium.svg#DINNextLTW23-Medium') format('svg');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DINNext';
  src: url('../fonts/DINNext/DINNextLTW23-Bold2.woff') format('woff'),
    url('../fonts/DINNext/DINNextLTW23-Bold2.ttf') format('truetype'),
    url('../fonts/DINNext/DINNextLTW23-Bold2.svg#DINNextLTW23-Bold2') format('svg');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'DINNext';
  src: url('../fonts/DINNext/DINNextLTW23-Black.woff') format('woff'),
    url('../fonts/DINNext/DINNextLTW23-Black.ttf') format('truetype'),
    url('../fonts/DINNext/DINNextLTW23-Black.svg#DINNextLTW23-Black') format('svg');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
} */
html {
  font-size: 16px;
}

html.increaseFont {
  font-size: 18px;
}

html.decreaseFont {
  font-size: 14px;
}

body {
  /* padding-top: 100px; */
  /* padding-top: 150px; */
  /* font-family: 'DINNext'; */
  font-family: "Noto Kufi Arabic", "Almarai", sans-serif;
  line-height: 1.5 !important;
}

[dir="rtl"] .modal-header .btn-close {
  margin: -0.5625rem auto -0.5625rem -0.75rem !important;
}

[lang="ar"] {
  font-family: var(--font-familyAR);
}

[lang="en"] {
  font-family: var(--font-familyEN);
}

.searchCollapse {
  top: 6rem;
}

.dropdown-toggle::after {
  content: "\ea4b";
  font-size: 1rem !important;
}

.dropdown-toggle:hover::after {
  content: "\ed36";
}

/* .products-category[ng-reflect-model=true]~label {
  color: var(--primaryColor) !important;
  border-bottom: 1px solid var(--secondaryColor);
} */
.products-category[type="checkbox"]:checked~label {
  color: var(--primaryColor) !important;
  border-bottom: 1px solid var(--secondaryColor);
}

/* .beta-style {
  margin-top: 2.8rem;
}

@media only screen and (max-width: 991px) {
  .beta-style {
    margin-top: 3.8rem;
  }
}

@media only screen and (max-width: 550px) {
  .beta-style {
    margin-top: 5.2rem;
  }

  body {
    padding-top: 125px !important;
  }
} */

@media only screen and (max-width: 1200px) {
  /* body { 
     padding-top: 60px;
    padding-top: 100px;
  }*/

  .offcanvas-start {
    width: 100% !important;
    border-left: 0px solid rgba(0, 0, 0, 0.2);
  }

  .offcanvas-end {
    width: 100% !important;
  }

  .offcanvas-body {
    background-color: var(--primaryColor);
  }

  .cookie .offcanvas-body {
    background-color: inherit;
  }

  .navbar-light .navbar-nav .nav-link,
  .dropdown-item {
    color: var(--white) !important;
  }

  .offcanvas-header .btn-close {
    color: var(--white);
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/0.75em auto no-repeat;
  }

  .searchCollapse {
    top: 3.5rem;
  }
}

.offcanvas.cookie {
  height: 125px;
}

@media only screen and (max-width: 991px) {
  .offcanvas.cookie {
    height: 175px;
  }
}

@media only screen and (max-width: 497px) {
  .offcanvas.cookie {
    height: 225px;
  }
}

.dropdown-menu {
  margin-top: 0px !important;
}

.breadcrumb-item {
  color: var(--primaryColor);
}

.breadcrumb-item>a {
  color: var(--primaryColor);
}

.breadcrumb-item:hover>a {
  color: var(--secondaryColor);
}

ol.breadcrumb li {
  overflow: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap;
  max-width: 320px; */
}

[dir="ltr"] .breadcrumb-item+.breadcrumb-item::before {
  font-family: boxicons;
  color: var(--primaryColor) !important;
  content: "\ea50";
}

[dir="rtl"] .breadcrumb-item+.breadcrumb-item::before {
  font-family: boxicons;
  color: var(--primaryColor) !important;
  content: "\ea4d";
}

@media only screen and (max-width: 991px) {
  .breadcrumb {
    margin-top: 1.2rem;
    display: flex;
    flex-direction: column;
  }

  .breadcrumb li {
    padding-right: 0px !important;
    margin: 3px 0;
  }
}

/* ETEC Table Style */
/* 
.table>:not(caption)>*>* {
  background-color: var(--white);
} */

.table> :not(.table-etec)>*>* {
  background-color: var(--primaryColor) !important;
}

.etec-table thead tr th:nth-child(even) {
  background-color: var(--lightprimaryColor) !important;
}

.etec-table tbody tr td {
  border-top: 0.5rem solid var(--white);
  padding: 1rem 0.5rem;
  text-align: center;
}

.etec-table tbody tr td:nth-child(odd) {
  background: #ecedf6 !important;
}

.etec-table tbody tr td:nth-child(even) {
  background: #dbd8e8 !important;
}

.table:not(.table-etec) tbody th,
.table:not(.table-etec) thead:not(.thead-etec) th {
  color: var(--white) !important;
  text-align: center;
}

.btn-sort::before {
  font-family: boxicons;
  color: var(--primaryColor) !important;
  content: "\ec1d";
}

.btn-sort:hover::before {
  color: var(--white) !important;
}

.label-check {
  color: var(--primaryColor) !important;
  border-color: var(--primaryColor);
  opacity: 0.35 !important;
}

.label-check:hover {
  color: var(--primaryColor) !important;
}

.btn-check:active+.label-check,
.btn-check:checked+.label-check,
.label-check.active,
.label-check.dropdown-toggle.show,
.label-check:active {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
  color: var(--white) !important;
  opacity: 1 !important;
}

/* .label-check:hover {
  background-color: none !important;
  color: var(--primaryColor) !important;
} */

[dir="rtl"] .mat-option-text,
[dir="rtl"] .mat-select-value-text {
  font-family: "DINNext" !important;
}

.btn-sort .mat-select-value .mat-select-value-text .mat-select-min-line {
  color: #4c3d8f;
}

.btn-sort:hover .mat-select-value .mat-select-value-text .mat-select-min-line {
  color: #fff;
}

.data-bar:nth-child(odd) {
  background-color: #5c79ba !important;
}

.data-bar:nth-child(even) {
  background-color: var(--white) !important;
}

.data-bar:nth-child(even) h2 {
  color: var(--primaryColor) !important;
}

.card-striped div:nth-child(odd) {
  background-color: #ecedf6 !important;
}

.card-striped div:nth-child(even) {
  background-color: var(--white) !important;
}

.card-striped div.full {
  background-color: #94d0af !important;
}

.card-striped div.conditional {
  background-color: #f9c697 !important;
}

.card-striped div.expire {
  background-color: #808080 !important;
}

.card-striped div.expire p {
  color: var(--white) !important;
}

.link-color-force a {
  color: var(--white) !important;
}

.link-color-force {
  background-color: var(--lightprimaryColor);
}

/* .service-goals .col:last-child {
  width: 100%;
  justify-content: center;
  display: flex;
} */

/* .nav-tabs .nav-product {
  background-color: #b7b1d2 !important;
  color: #fff !important;
} */

.nav-product:hover {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
}

/* product nav */
.img-respon {
  /* width: 400px; */
  width: 250px;
}

@media only screen and (max-width: 991px) {
  .img-respon {
    /* width: 300px; */
    width: 150px;
  }
}

/* heroSwiper */
.text-hero {
  color: var(--heroColor);
}

.heroSwiper {
  height: 500px;
}

@media (max-width: 991px) {
  .heroSwiper {
    height: 375px;
  }

  .heroSwiper .swiper-pagination {
    bottom: 4rem !important;
  }
}

.cover {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

/* nav-hero */
.main-tabs-wrapper {
  position: absolute;
  top: -45px;
  z-index: 9;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.nav-tabs.main-tabs {
  margin: 0;
}

.nav-tabs.main-tabs .nav-link {
  /* margin: 12px !important; */
  margin: 0 0.625rem 0 0.625rem;
  border-radius: 0.7rem !important;
}

.nav-tabs.main-tabs .nav-link.active {
  /* border: 12px solid white !important; */
  /* margin: 0 !important; */
  box-shadow: 0 0 0 0.6em #fff;
}

@media (max-width: 991px) {
  .main-tabs-wrapper {
    left: 0px;
    transform: none;
    right: 0;
    top: -65px;
    position: relative;
    background-color: var(--white);
    padding: 12px 0px !important;
    border-radius: 0.375rem;
  }

  .nav-tabs.main-tabs {
    display: block;
    width: 100%;
  }

  .nav-tabs.main-tabs .nav-link {
    margin: 0 12px !important;
  }

  .nav-tabs.main-tabs .nav-link.active {
    box-shadow: 0 0 0 0em #fff;
    border: 0px !important;
    margin: 0px 12px !important;
  }

  .nav-tabs.main-tabs li .nav-link {
    border-radius: 0 !important;
  }

  .nav-tabs.main-tabs li:first-child .nav-link {
    border-top-left-radius: 0.375rem !important;
    border-top-right-radius: 0.375rem !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .nav-tabs.main-tabs li:last-child .nav-link {
    border-bottom-left-radius: 0.375rem !important;
    border-bottom-right-radius: 0.375rem !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
  }

  .tab-content.card {
    position: relative;
    /* top: -90px; */
    margin-top: -70px;
  }

  .card-body {
    padding: 1rem 1rem;
  }
}

.form-floating>.form-control,
.form-floating>.form-select {
  line-height: 4em !important;
}

.mat-form-field {
  /* font-family: 'DINNext'; */
  font-family: "Almarai", "Cairo", sans-serif;
}

/* shareicons */
.sb-group {
  flex-wrap: nowrap !important;
}

.sb-icon {
  min-width: 1.5em !important;
}

.share-opinion {
  width: 5.5rem !important;
}

button.sb-wrapper.sb-show-icon {
  min-width: auto !important;
  color: var(--primaryColor) !important;
}

button.sb-wrapper.sb-show-icon:hover {
  color: var(--white) !important;
}

/* search on home */
.mat-form-field-flex {
  border-radius: 0.5rem !important;
  border: 1px solid var(--disable);
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: transparent !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: transparent !important;
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: transparent !important;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent !important;
}

.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-color: transparent !important;
}

/* toaster */
.toast-success {
  background-color: #4db27b !important;
}

.toast-error {
  background-color: #fb7186 !important;
}

.toast-info {
  background-color: #52b5c3 !important;
}

.toast-warning {
  background-color: #f5a053 !important;
}

/* Preloader */

#spinner {
  background-color: #f7f7f7 !important;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  opacity: 0.8 !important;
}

/* #preloader {
 background-color: #F7F7F7;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
} */

#preloader img {
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
  position: absolute !important;
}

#preloader #loader {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #4c3d8f;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

#preloader #loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #999efc;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
}

#preloader #loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #52b5c3;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hero-img {
  object-fit: cover;
  object-position: 50% 50%;
  background-attachment: fixed;
  max-width: none;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* pointer-events: none;
transform-style: preserve-3d;
backface-visibility: hidden;
will-change: transform, opacity;
margin-top: 185.6px;
transform: translate3d(0px, -185.6px, 0px); */
}

.navbar-light.is-home .navbar-nav .nav-link {
  color: var(--white);
}

.navbar-light.is-home .navbar-toggler-icon,
.navbar-light.is-home .navbar-toggler-icon::after,
.navbar-light.is-home .navbar-toggler-icon::before {
  background-color: #ffffff;
}

.navbar-light.is-home .navbar-toggler-icon {
  background-image: none;
}

[dir="rtl"] .navbar-stuck,
[dir="ltr"] .navbar-stuck {
  position: fixed !important;
  top: 0 !important;
  /* -webkit-animation: .25s ease-in-out;
      animation:  .25s ease-in-out;
      transition: 0.1s linear; */
}

[dir="rtl"] .navbar-stuck span,
[dir="ltr"] .navbar-stuck span {
  opacity: 0.95 !important;
}

@media (min-width: 1200px) {
  .header-top {
    /* top: 53px !important; */
    top: 45px !important;
  }

  [dir="rtl"] .navbar-stuck .offcanvas-body,
  [dir="ltr"] .navbar-stuck .offcanvas-body {
    padding: 0px !important;
    transition: 0.3s ease all;
  }

  [dir="rtl"] .navbar-stuck img,
  [dir="ltr"] .navbar-stuck img {
    width: 200px !important;
    transition: 0.3s ease all;
  }
}

/* internal tabs hover */
.nav-tabs.content-tabs .nav-link.show:not(.active),
.nav-tabs.content-tabs .nav-link:hover:not(.active),
.content-tabs .nav-link:focus,
.content-tabs .nav-link:hover {
  background-color: var(--primaryColor) !important;
  color: var(--white) !important;
}

/* guide-manual-img */
.guide-manual-img {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .guide-manual-img {
    position: relative;
    top: auto;
    left: auto;
    transform: translateY(0);
  }
}

/* scroll style */
::-webkit-scrollbar {
  width: 0.45rem;
  border-radius: 2rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: var(--primaryColor);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--secondaryColor);
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  display: none;
  height: 0px !important;
}

.mat-form-field-underline {
  width: 0px !important;
}

/* lightgallery */
img.lg-object.lg-image {
  width: auto !important;
  height: auto !important;
}

[dir="rtl"] .lg-outer .lg-inner {
  -webkit-transform: translate3d(100%, 0, 0) !important;
  transform: translate3d(100%, 0, 0) !important;
  transition: opacity 0s !important;
}

[dir="rtl"] .lg-outer .lg-item.lg-complete.lg-zoomable .lg-image.reset-transition,
[dir="rtl"] .lg-outer .lg-item.lg-complete.lg-zoomable .lg-dummy-img.reset-transition {
  transform: scale3d(1, 1, 1) translate3d(50%, -50%, 0px) !important;
  left: -50% !important;
}

.lg-backdrop,
.lg-outer {
  z-index: 99999 !important;
}

/* .gallery-container>a {
  max-width: 200px;
  max-height: 150px;
  padding: 5px;
  disply: inline-block;
} */

/* mouse */

@-webkit-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }

  15% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 50%;
  }

  100% {
    opacity: 0;
    top: 29%;
  }
}

@-moz-keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }

  15% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 50%;
  }

  100% {
    opacity: 0;
    top: 29%;
  }
}

@keyframes ani-mouse {
  0% {
    opacity: 1;
    top: 29%;
  }

  15% {
    opacity: 1;
    top: 50%;
  }

  50% {
    opacity: 0;
    top: 50%;
  }

  100% {
    opacity: 0;
    top: 29%;
  }
}

.scroll-btn {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 60px;
  text-align: center;
  z-index: 99;
}

.scroll-btn>* {
  /* display: inline-block; */
  line-height: 18px;
  font-size: 13px;
  font-weight: normal;
  color: #7f8c8d;
  color: #ffffff;
}

.scroll-btn>*:hover,
.scroll-btn>*:focus,
.scroll-btn>*.active {
  color: #ffffff;
}

.scroll-btn>*:hover,
.scroll-btn>*:focus,
.scroll-btn>*:active,
.scroll-btn>*.active {
  opacity: 0.8;
  filter: alpha(opacity=80);
}

.scroll-btn .mouse {
  position: relative;
  display: block;
  /* width: 35px;
  height: 55px; */
  width: 32px;
  height: 52px;
  margin: 0 auto 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* border: 3px solid white; */
  border: 2px solid white;
  border-radius: 23px;
}

.scroll-btn .mouse>* {
  position: absolute;
  display: block;
  top: 29%;
  left: 50%;
  width: 7px;
  height: 7px;
  margin: -4px 0 0 -4px;
  background: white;
  border-radius: 50%;
  -webkit-animation: ani-mouse 2.5s linear infinite;
  -moz-animation: ani-mouse 2.5s linear infinite;
  animation: ani-mouse 2.5s linear infinite;
}

/* scroll-to-top */
[dir="ltr"] .btn-scroll-totop {
  left: 1rem;
}

[dir="rtl"] .btn-scroll-totop {
  right: 1rem;
}

.btn-scroll-totop {
  width: 2.5rem;
  height: 2.5rem;
  bottom: 1rem;
  transition:
    transform 0.25s ease-in-out,
    opacity 0.25s;
  box-shadow: 0 0.275rem 1.125rem rgba(18, 21, 25, 0.1);
  z-index: 99;
}

.isTop {
  transform: scale(0);
}

/* order list */
.order-list ol {
  list-style: none;
  counter-reset: item;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

@media (min-width: 1200px) {
  .order-list ol li {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}

@media (max-width: 1200px) {
  .order-list ol {
    flex-direction: column;
  }
}

.order-list ol li {
  counter-increment: item;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: start;
}

.order-list ol li:before {
  content: counter(item);
  background: var(--secondaryColor) !important;
  border-radius: 100%;
  color: white;
  min-width: 2rem;
  min-height: 2rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1200px) {
  [dir="rtl"] .order-list ol li {
    padding-left: 1rem;
  }

  [dir="ltr"] .order-list ol li {
    padding-right: 1rem;
  }

  .order-list ol li:last-child {
    padding-left: 0rem;
  }
}

[dir="rtl"] .order-list ol li:before {
  margin-left: 1rem;
  margin-right: 0px;
}

[dir="ltr"] .order-list ol li:before {
  margin-right: 1rem;
  margin-left: 0px;
}

/* products checkbox style */

.products-checkbox label {
  justify-content: start !important;
  inline-size: max-content;
  cursor: pointer;
}

[dir="rtl"] .products-checkbox label {
  padding-right: 1.7rem;
}

[dir="ltr"] .products-checkbox label {
  padding-left: 1.7rem;
}

.products-checkbox input {
  width: 1.2rem;
  height: 1.2rem;
  margin-top: 0.8rem;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid #b4b7c9;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  border-radius: 100%;
  clip: auto;
}

[dir="rtl"] .products-checkbox input {
  margin-left: -1.5rem !important;
}

[dir="ltr"] .products-checkbox input {
  margin-right: -1.5rem !important;
}

.products-checkbox input:checked {
  background-color: var(--secondaryColor);
  border-color: var(--secondaryColor);
}

.products-checkbox input:checked[type="checkbox"] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

/**************** 
ND 93 
****************/
.nd-logo {
  top: 10%;
}

.nd-logo img {
  width: 250px;
}

@media only screen and (max-width: 768px) {
  .nd-logo {
    top: 5%;
  }

  .nd-logo img {
    width: 200px;
  }
}

.orange-wave {
  content: "";
  background: url('src/assets/media/ND93/orange-wave.png') bottom no-repeat;
  z-index: 4;
  width: 100%;
  bottom: 0;
    left: 0;
}

.blue-wave {
  content: "";
  background: url('src/assets/media/ND93/blue-wave.png') bottom no-repeat;
  width: 72.6%;
  bottom: 5%;
  z-index: 2;
    left: 0;
}

.nd-diriyah-1 {
  background: url('src/assets/media/ND93/nd-diriyah-1.png') no-repeat center bottom;
  bottom: 1.7%;
  width: 25%;
  z-index: 3;
    left: 25%;
}


.nd-diriyah-2 {
  background: url('src/assets/media/ND93/nd-diriyah-2.png') no-repeat center bottom;
  bottom: 3.7%;
  width: 23.5%;
  z-index: 2;
    right: 40.7%;
}

@media only screen and (max-width: 768px) {
  .orange-wave {
    width: 160%;
        right: -13%;
  }

  [dir="ltr"] .orange-wave {
    left: auto;
  }

  .blue-wave {
    width: 167.6%;
    bottom: 5%;
        right: 0%;
  }

  [dir="ltr"] .blue-wave {
    left: auto;
  }

  .nd-diriyah-1 {
    bottom: 0%;
    width: 65%;
    right: 43%;
  }


  [dir="ltr"] .nd-diriyah-1 {
    left: auto;
  }

  .nd-diriyah-2 {
    bottom: 17%;
    width: 58%;
        right: 27.7%;
  }

  [dir="ltr"] .nd-diriyah-2 {
    left: auto;
  }
}

.nd-height {
  max-height: 675px;
  bottom: 0;
  right: 0;
  left: 0;
}


@media screen and (min-width:1500px) and (max-width:1600px) {
  .nd-height {
    max-height: 700px
  }
}

@media only screen and (max-width: 1450px) {
  .nd-height {
    max-height: 695px;
  }
}

@media only screen and (max-width: 1350px) {
  .nd-height {
    max-height: 595px;
  }
}

@media only screen and (max-width: 1250px) {
  .nd-height {
    max-height: 495px;
  }
}

@media only screen and (max-width: 1150px) {
  .nd-height {
    max-height: 395px;
  }
}

@media only screen and (max-width: 950px) {
  .nd-height {
    max-height: 300px;
  }
}

@media only screen and (max-width: 640px) {
  .nd-height {
    max-height: 300px;
    /* bottom: 14%; */
  }

  .bg-bottom {
    height: 14%;
  }
}

@media only screen and (max-width: 540px) {
  .nd-height {
    max-height: 235px;
  }
}

@media only screen and (max-width: 440px) {
  .nd-height {
    /* bottom: 13%; */
  }
}

@media only screen and (max-width: 440px) {
  .nd-height {
    max-height: 180px;
  }
}


[data-theme="dark"] .nd-height {
  filter: grayscale(1) brightness(500%) !important;
  -webkit-filter: grayscale(1) brightness(100%) !important;
}
/**************** 
END ND 93 
****************/
.nav.nav-pills::before {
  content: " ";
  position: absolute;
  top: 35px;
  width: 100%;
  height: 1px;
  z-index: 0;
  background: rgba(221, 221, 221, .8);
}

.nav-pills .nav-link,
.nav-pills .show>.nav-link,
.nav-pills .nav-link {
  color: #000 !important;
  background-color: #eaeaea;
}

.hero-mh {
  min-height: 500px;
}